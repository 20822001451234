import { Record, List } from 'immutable'

const CustomerRecord = Record({
  accountingLockDate: undefined,
  address: '',
  code: '',
  companies: List(),
  customLogoUrl: '',
  customNavbarBackgroundColor: '',
  customNavbarColor: '',
  hideSystemDashboards: false,
  hideSystemReportSchemes: false,
  forceAAD: false,
  id: undefined,
  name: '',
  allowSelfSignUp: '',
})

export default CustomerRecord
