import { delay } from 'redux-saga'
import {
  all,
  call,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import authApi from 'api/auth-api'
import token from 'api/TokenStorage'
import mainApi from 'api/MainApi'
import usersApi from 'api/UsersApi'

import {
  CANCEL_ACCOUNT_CONVERSION,
  CHOOSE_PROFILE,
  CHOSE_PROFILE,
  GET_LOGGED_IN_USER_INFO,
  PASSWORD_LOGIN,
  LOGOUT,
  CHECK_LOGIN,
  LOGIN_SUCCESS,
  SEND_INVITE,
  INITIALIZE_LEMONSOFT_USER,
  FINVOICER_ID_TOKEN_FLOW,
} from 'containers/App/constants'
import {
  chooseProfile as chooseProfileAction,
  inviteError,
  loginSuccess,
  logoutError,
  logoutSuccess,
  getLoggedInUserInfo as getLoggedInUserInfoAction,
  initializeLemonsoftUser as initializeLemonsoftUserAction,
  getLoggedInUserInfoError,
  getSubUsersError,
  getSubUsersSuccess,
  startUserAccountConversion,
  getLoginMethod,
  getPermissionsSuccess,
  passwordLoginError,
  selectLemonsoftOrganization,
  initializeLemonsoftUserError,
  initializeLemonsoftUserProgress,
  finvoicerIdTokenFlowAction,
} from 'containers/App/actions'
import { getCustomers } from 'containers/Customers/actions'
import useAD from 'utils/featureFlagReaders/useAD'
import { removeDomainIfExists } from 'utils/parseUrl'

import * as msal from '@azure/msal-browser'
import {
  finvoicerConfig,
  lemonsoftConfig,
  lemonsoftRequest,
  msalConfig,
} from '../../authConfig'
import { loginRequest } from '../../authConfig'
import useLemon from 'utils/featureFlagReaders/useLemon'
import { eventChannel, END } from 'redux-saga'
import { handleError } from 'api/api-utils'
import { tryParseErrorJson } from 'utils/parseError'
import {
  DASHBOARD_EXPANDED_GROUPS_STORAGE_KEY,
  DASHBOARD_GROUP_ORDER_STORAGE_KEY,
} from 'containers/Dashboards/constants'
import { encryptMessage } from 'utils/sha512encrypt'

const REFRESH_TOKEN_IN_MINUTES = 15
const PERSISTENT_APP_DATA = [
  DASHBOARD_EXPANDED_GROUPS_STORAGE_KEY,
  DASHBOARD_GROUP_ORDER_STORAGE_KEY,
]

export const msalInstance = useAD
  ? new msal.PublicClientApplication(msalConfig)
  : undefined

export const lemonMsal = useLemon
  ? new msal.PublicClientApplication(lemonsoftConfig)
  : undefined

export function* passwordLoginFlow(action) {
  const { username, password, navigate } = action
  try {
    const data = yield call(authApi.login, username, password)
    token.setTokens(data.access_token, data.refresh_token)
    const userData = yield call(usersApi.getLoggedInUser)
    if (!userData.aadUserId && useAD) {
      yield put(startUserAccountConversion(userData.forceAAD))
      return
    }
    yield put(getLoggedInUserInfoAction({ navigate }))
  } catch (error) {
    yield put(passwordLoginError(error))
  }
}

export function* finvoicerIdTokenFlow(action) {
  const { idToken, navigate } = action
  try {
    const challengeVerifier = localStorage.getItem('challenge_verifier')
    const data = yield call(authApi.loginWithFinvoicerIdToken, {
      token: idToken,
      challengeVerifier,
    })
    localStorage.removeItem('challenge_verifier')
    token.setTokens(data.access_token, data.refresh_token)
    yield put(getLoggedInUserInfoAction({ navigate }))
  } catch (error) {
    localStorage.removeItem('challenge_verifier')
    yield put(getLoggedInUserInfoError(error?.response || error))
  }
}

// This saga fetches minimum required data
// and handles redirection to profile choosing if failing
export function* getLoggedInUserInfo(action) {
  const { navigate } = action
  try {
    const userData = yield call(usersApi.getLoggedInUser)
    const permissions = yield call(mainApi.getPermissions)
    if (!userData || !permissions) {
      yield put(getLoggedInUserInfoError('No user data found'))
      return
    }
    yield put(getPermissionsSuccess(permissions))
    yield put(getCustomers()) //customer reducer and saga handle customers
    yield call(redirectToDefaultPage, userData, navigate)
    yield put(loginSuccess(userData))
  } catch (err) {
    const missingSubUserHeaderError = err?.data?.code === 'E-0302'
    const invalidSubUserHeaderError = err?.data?.code === 'E-00031'
    if (missingSubUserHeaderError || invalidSubUserHeaderError) {
      // This is done through error as single subuser users can ignore entire flow
      yield put(chooseProfileAction())
      return
    }
    const isForcedToConvert = err?.data?.code === 'E-0301'
    if (isForcedToConvert) {
      yield put(startUserAccountConversion(true))
      return
    }
    const isBlacklistedAccessTokenError = err?.data?.code === 'E-0304'
    if (isBlacklistedAccessTokenError) {
      localStorage.clear()
      yield put(getLoginMethod())
      return
    }
    const unInitializedLemonsoftUserError = err?.data?.code === 'E-0500'
    if (unInitializedLemonsoftUserError) {
      yield put(initializeLemonsoftUserAction(action))
      return
    }
    const networkError = err?.message === 'Network Error'
    if (networkError) {
      const [cancel] = yield race([take(LOGOUT), delay(5000)])
      if (cancel) return
      yield put(getLoggedInUserInfoAction({ ...action }))
      return
    }
    yield put(getLoggedInUserInfoError(err))
  }
}

// This flow is only and always called when app is first mounted.
// Responsible for refreshing expired tokens and initializing login status
export function* initialLoginCheckFlow(action) {
  try {
    var search = new URLSearchParams(window.location.search)
    if (search.get('finvoicer_id')) {
      yield put(
        finvoicerIdTokenFlowAction(search.get('finvoicer_id'), action.navigate)
      )
      return
    }
    if (search.get('login_hint') === 'finvoicer') {
      const challenge_verifier = crypto.randomUUID()
      localStorage.setItem('challenge_verifier', challenge_verifier)
      const challenge_code = yield encryptMessage(challenge_verifier)
      const url = `${finvoicerConfig.url}?client_id=${
        finvoicerConfig.clientId
      }&state=${encodeURI(window.location.origin)}&nonce=${challenge_code}`
      window.location.href = url
    }

    const hasActiveTokenInStore = token.isLoggedIn()
    if (hasActiveTokenInStore) {
      yield put(getLoggedInUserInfoAction(action))
      return
    }
    // Try refreshing token.
    yield call(refreshToken)
    const hasActiveTokenInStoreAfterResfresh = token.isLoggedIn()
    if (hasActiveTokenInStoreAfterResfresh) {
      yield put(getLoggedInUserInfoAction(action))
      return
    }

    // No token found. User is not logged in.
    yield put(getLoginMethod())
  } catch (err) {
    console.log('checkLoginFlow encountered an error', err)
    yield put(getLoginMethod())
  }
}

export function* refreshTokenLoop() {
  while (true) {
    const [cancel] = yield race([
      take(LOGOUT),
      delay(REFRESH_TOKEN_IN_MINUTES * 60000),
    ])
    if (cancel) return
    yield call(refreshToken)
  }
}

export function* refreshToken() {
  const { access_token, refresh_token } = token.getTokens()
  if (!token.isADToken() && refresh_token) {
    // Legacy tokens are refreshed with api call to backend
    try {
      const data = yield call(authApi.refreshToken, access_token, refresh_token)
      token.setAccessToken(data.access_token)
    } catch (err) {
      console.log(err)
    }
    return
  }
  if (!msalInstance && !lemonMsal) return

  try {
    const unhandledRedirect = localStorage.getItem('redirectType')
    if (unhandledRedirect) {
      localStorage.removeItem('redirect')
      var handler = unhandledRedirect === 'entra' ? msalInstance : lemonMsal
      // This causes a full page load if current page != page we logged in from -> after that checking progress restarts
      var response = yield handler.handleRedirectPromise()
      localStorage.removeItem('redirectType')
      const accessToken = response.accessToken
      token.setTokens(accessToken, accessToken)
      return
    }
  } catch (err) {
    console.log('handleRedirectPromise failed', err)
  }

  const activeEntraAccounts = msalInstance
    .getAllAccounts()
    .filter((acc) => acc.environment !== 'lemonsoftb2c.b2clogin.com')
  if (activeEntraAccounts.length > 0) {
    try {
      const accessTokenRequest = {
        ...loginRequest,
        account: activeEntraAccounts[0],
      }
      const tokenResponse = yield msalInstance.acquireTokenSilent(
        accessTokenRequest
      )
      console.log('Acquire entra token silent success', accessTokenRequest)
      const accessToken = tokenResponse.accessToken
      token.setTokens(accessToken, accessToken)
    } catch (err) {
      console.log('Acquire entra token silent failed', err)
      try {
        const tokenResponse = yield msalInstance.acquireTokenPopup(loginRequest)
        const accessToken = tokenResponse.accessToken
        token.setTokens(accessToken, accessToken)
      } catch (err) {
        console.log('Acquire entra token popup failed', err)
      }
    }
  }

  const activeLemonAccounts = msalInstance
    .getAllAccounts()
    .filter((acc) => acc.environment === 'lemonsoftb2c.b2clogin.com')
  if (activeLemonAccounts.length > 0) {
    try {
      const accessTokenRequest = {
        ...lemonsoftRequest,
        account: activeLemonAccounts[0],
      }
      const tokenResponse = yield lemonMsal.acquireTokenSilent(
        accessTokenRequest
      )
      const accessToken = tokenResponse.accessToken
      token.setTokens(accessToken, accessToken)
    } catch (err) {
      console.log('Acquire entra token silent failed', err)
      try {
        const tokenResponse = yield lemonMsal.acquireTokenPopup(loginRequest)
        const accessToken = tokenResponse.accessToken
        token.setTokens(accessToken, accessToken)
      } catch (err) {
        console.log('Acquire entra token popup failed', err)
      }
    }
  }
}

export function* logoutFlow(action) {
  try {
    const valuesToKeep = PERSISTENT_APP_DATA.map((key) =>
      localStorage.getItem(key)
    )
    if (token.isLemonsoftToken() && lemonMsal) {
      localStorage.clear()
      yield redirectToRoot(action)
      // We choose to NOT logout from lemonsoft as user most likely just wants to log our from Finazilla only
      // Difference from AD logout is that AD is Finazilla specific directory so we do complate logout
      // This also forces us NOT to blacklist the lemonsoft token. Which is kinda of a bad practice
      // yield lemonMsal.logoutRedirect()
      return
    }

    // Blacklist current token - Note that if logout redirect fails or is not run this can cause issues
    yield call(authApi.logout)

    if (token.isADToken() && msalInstance) {
      // clear localstorage to prevent pop-up flow from relogging user in automatically
      // in case logout redirect fails
      localStorage.clear()
      yield msalInstance.logoutRedirect()
      return
    }

    localStorage.clear()
    valuesToKeep.map((value, index) =>
      localStorage.setItem(PERSISTENT_APP_DATA[index], value)
    )
    yield redirectToRoot(action)
  } catch (error) {
    yield put(logoutError(error))
  }
}

export function redirectToDefaultPage(user, navigate) {
  // Redirect to users defaultpage if not logging in through a link to specific path
  // eslint-disable-next-line no-restricted-globals
  if (user?.defaultPage && location.pathname === '/' && navigate) {
    navigate(removeDomainIfExists(user.defaultPage))
  }
}

export function* redirectToRoot(action) {
  const { navigate } = action
  yield put(logoutSuccess())
  yield navigate('/')
}

export function* sendInvite(action) {
  // console.log('sendInvite', action.inviteEmail)
  try {
    const inviteEmail = action.inviteEmail
    yield call(usersApi.invite, { inviteEmail })
  } catch (error) {
    yield put(inviteError(error))
  }
}

export function* getSubUsers() {
  try {
    const usersData = yield call(usersApi.getSubUsers)
    yield put(getSubUsersSuccess(usersData))
  } catch (err) {
    yield put(getSubUsersError(err))
  }
}

export function* initializeLemonsoftUser(action) {
  try {
    const organizations = yield call(mainApi.getOrganizations)
    const specifiedOrganization =
      organizations.length === 1
        ? organizations[0]
        : organizations.find((org) => org.id === action?.organizationId)
    if (specifiedOrganization) {
      const channel = eventChannel((eventEmmit) => {
        const onMessage = (event) => {
          eventEmmit(getLoggedInUserInfoAction({ navigate: action.navigate }))
          eventEmmit(END)
        }
        const onProgress = (event) => {
          eventEmmit(initializeLemonsoftUserProgress(event.data))
        }
        const onError = (event) => {
          console.error('Error in initializeLemonsoftUser', event)
          eventEmmit(
            handleError(
              { data: tryParseErrorJson(event.data) },
              initializeLemonsoftUserError
            )
          )
          eventEmmit(END)
        }

        mainApi.initializeLemonsoftUser({
          businessId: specifiedOrganization.vat,
          businessUnit: specifiedOrganization.unit,
          onMessage,
          onProgress,
          onError,
        })

        return () => {}
      })
      while (true) {
        const event = yield take(channel)
        if (event === END) break
        yield put(event)
      }
      console.log('Channel closed 2')
      return
    }

    yield put(selectLemonsoftOrganization(organizations))
  } catch (err) {
    console.log('createCustomer error', err)
    yield put(initializeLemonsoftUserError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* rootSaga() {
  yield all([
    takeEvery(PASSWORD_LOGIN, passwordLoginFlow),
    takeEvery(LOGOUT, logoutFlow),
    takeEvery(CHECK_LOGIN, initialLoginCheckFlow),
    takeEvery(GET_LOGGED_IN_USER_INFO, getLoggedInUserInfo),
    takeEvery(GET_LOGGED_IN_USER_INFO, getSubUsers),
    takeEvery(CHOSE_PROFILE, getLoggedInUserInfo),
    takeLatest(CANCEL_ACCOUNT_CONVERSION, getLoggedInUserInfo),
    takeEvery(CHOOSE_PROFILE, getSubUsers),
    takeLatest(LOGIN_SUCCESS, refreshTokenLoop),
    takeLatest(SEND_INVITE, sendInvite),
    takeLatest(INITIALIZE_LEMONSOFT_USER, initializeLemonsoftUser),
    takeLatest(FINVOICER_ID_TOKEN_FLOW, finvoicerIdTokenFlow),
  ])
}
